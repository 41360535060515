import React from 'react';
import {
  Chart as ChartJS,
  registerables
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

import { processDataForMultitype } from './helpers';

ChartJS.register(...registerables);

const options = {
  scales: {
    yAxes: {
      grid: {
        borderColor: '#030d22',
        color: '#030d22',
        lineWidth: 0,
        drawTicks: false
      },
      ticks: {
        display: false
      },
      display: false
    },
    xAxes: {
      grid: {
        borderColor: '#030d22',
        color: '#030d22',
        lineWidth: 0,
        drawTicks: false
      },
      ticks: {
        display: false
      },
      display: false
    },
  },
  plugins: {
    tooltip: {
      intersect: false
    }
  },
  maintainAspectRatio: false
}

const Multitype = ({ data, isLoading }) => {
  const processedData = processDataForMultitype(data);

  return (
    <Chart options={options} data={processedData} />
  );
}

export default Multitype;
