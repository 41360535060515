import React, { useReducer, createContext } from 'react';

const QueryContext = createContext();

const initialState = {
  fetchAll: false,
  molecules: [],
  range: {
    start: null,
    end: null
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_QUERY':
      return {
        ...state,
        ...action.payload
      }
    default:
      return state;
  }
};

function QueryContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { query: state, dispatchQuery: dispatch };

  return (
    <QueryContext.Provider value={value}>
      {children}
    </QueryContext.Provider>
  );
}

const QueryContextConsumer = QueryContext.Consumer;

export { QueryContext, QueryContextProvider, QueryContextConsumer };
