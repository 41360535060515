import React, { useMemo, Fragment, useState } from 'react';
import ReactDataSheet from 'react-datasheet';

import 'react-datasheet/lib/react-datasheet.css';
import './index.css';

import { processDataForDataSheet, getSelectionByMouseEvents } from './helpers';

import Loading from '../loading'

const DataSheet = ({ data, isLoading }) => {
  const [selected, setSelected] = useState({})

  const processedData = useMemo(() => {
    return processDataForDataSheet(data)
  }, [data])

  const handleContextMenu = () => {}

  const handleOnSelect = ({ start, end }) => {
    const updatedSelection = getSelectionByMouseEvents({
      processedData,
      start,
      end
    })

    setSelected(updatedSelection)
  }

  if (isLoading) return (
    <Loading />
  );

  return (
    <Fragment>
      <ReactDataSheet
        data={processedData.rows}
        valueRenderer={cell => cell.value}
        onContextMenu={handleContextMenu}
        selected={selected}
        onSelect={handleOnSelect}
      />
    </Fragment>
  );
}

export default DataSheet