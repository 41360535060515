import React, { Fragment } from "react";
import { Input } from 'reactstrap';

import './index.css';

const Atom = ({
  numAtoms,
  atom,
  onChangeAtom,
  moleculeId,
  isError
}) => {
  if (atom.type === 'selection') {
    return (
      <tr className="atom-row" key={atom.id}>
        <td>
          {atom.placeholder}{atom.required ? '*' : ''}
        </td>
        <td>
          <Input
            id={atom.id}
            type="select"
            className={`atom-field ${isError ? 'form-error' : ''}`}
            onChange={event => onChangeAtom({
              atomId: atom.id,
              moleculeId,
              event
            })}
            value={atom.value}
          >
            {atom.options.map(option => {
              return (
                <option key={option} value={option}>{option}</option>
              )
            })}
          </Input>
        </td>
      </tr>
    );
  }

  if (atom.type === 'checkbox') {
    return (
      <tr className="atom-row" key={atom.id}>
        <td>
          {atom.placeholder}{atom.required ? '*' : ''}
        </td>
        <td>
          <Input
            id={atom.id}
            type="checkbox"
            className={`atom-field ${isError ? 'form-error' : ''}`}
            checked={Boolean(atom.value)}
            onClick={event => onChangeAtom({
              atomId: atom.id,
              moleculeId,
              event: {
                target: {
                  value: !Boolean(atom.value)
                }
              }
            })}
          />
        </td>
      </tr>
    );
  }

  return (
    <tr className="atom-row" key={atom.id}>
      <td>
        {atom.label}{atom.required ? ' *' : ''}
        {atom.info && <br/>}
        {atom.info && <span className="atom-info">{atom.info}</span>}
      </td>
      <td>
        <Input
          type={atom.type}
          id={atom.id}
          className={`atom-field ${isError ? 'form-error' : ''}`}
          size="sm"
          placeholder={atom.placeholder}
          onChange={event => onChangeAtom({
            atomId: atom.id,
            moleculeId,
            event
          })}
          value={atom.value}
        />
      </td>
    </tr>
  );
}

export default Atom;
