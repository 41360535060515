import { each, isEmpty, keys, map, find, sample } from 'lodash';

import { findLabelValue, parseId, findMeasurementValue } from '../../../utils/helpers';
import { formMoleculesWithDerivations as formMolecules } from '../../../services/form-builder';

import {
  MEASUREMENT_SENTIMENT_LABEL,
  MEASUREMENT_LENGTH_OF_TEXT_LABEL,
  MEASUREMENT_EXISTENCE_LABEL,
  MEASUREMENT_LAST_ENTRY_LABEL,
  MEASUREMENT_COUNTABLE_LABEL
} from '../../../services/form-builder/constants';

import { CHART_COLORS } from './constants';

const getChartConfigByMoleculeInfo = moleculeInfo => {
  const moleculeId = parseId(moleculeInfo.id);
  const color = sample(CHART_COLORS);

  // TODO: make more comprehensive
  if (moleculeInfo.id === 'gap|derived') {
    return {
      type: 'line',
      borderColor: color,
      tension: 0.4,
      pointRadius: 0,
      color: color
    }
  }

  const measurement = findMeasurementValue({
    collection: moleculeInfo.atoms,
    defaultValue: MEASUREMENT_LAST_ENTRY_LABEL
  })

  if (moleculeId === 'yesnoMolecule') {
    return {
      type: 'bar',
      borderWidth: '2',
      borderColor: color,
    }
  }

  return {
    type: 'line',
    tension: measurement === MEASUREMENT_COUNTABLE_LABEL ? 0 : 0.4,
    fill: measurement === MEASUREMENT_COUNTABLE_LABEL,
    borderColor: color,
    color: color
  }
}

export const processDataForMultitype = ({ stats, definitions }) => {
  const labels = []
  const datasetMapping = {}

  each(stats, ({ date, data }) => {
    if (!isEmpty(data)) {
      labels.push(date);

      const definitionIds = keys(data);

      each(definitionIds, definitionId => {
        const moleculeIds = keys(data[definitionId])

        each(moleculeIds, moleculeId => {
          if (moleculeId !== 'tags|derived') {
            const { count, value, entries } = data[definitionId][moleculeId];

            const moleculeInfo = find(definitions[definitionId].molecules, { id: moleculeId });
            const moleculeLabel = findLabelValue({
              collection: moleculeInfo.atoms,
              defaultValue: moleculeInfo.label
            })

            const formattedValue = formMolecules[parseId(moleculeId)]
              .format.data({ data: { count, value, entries }, molecule: moleculeInfo })

            if (datasetMapping[moleculeId]) {
              datasetMapping[moleculeId] = {
                ...datasetMapping[moleculeId],
                data: [...datasetMapping[moleculeId].data, formattedValue]
              }
            } else {
              datasetMapping[moleculeId] = {
                ...getChartConfigByMoleculeInfo(moleculeInfo),
                label: moleculeLabel,
                data: [formattedValue]
              }
            }
          }
        })
      })
    }
  });

  const datasets = map(keys(datasetMapping), datasetKey => ({
    ...datasetMapping[datasetKey]
  }));

  return {
    labels,
    datasets
  }
}
