import React from "react";
import { BarLoader } from 'react-spinners';

const LOADING_COLOR = 'var(--bs-primary)';

const Loading = ({ className }) => (
  <div className={className}>
    <BarLoader color={LOADING_COLOR} />
  </div>
);

export default Loading;
