import React, { useReducer, createContext } from 'react';

const UserContext = createContext();

const initialState = {
  isLoading: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        ...action.payload.user,
        isLoading: false,
      }
    case 'SET_IS_LOADING':
      return {
        ...state,
        isLoading: action.payload.isLoading
      }
    case 'SET_TAGS':
      return {
        ...state,
        tags: action.payload.tags
      }
    default:
      return state;
  }
};

function UserContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { userState: state, dispatchUser: dispatch };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
}

const UserContextConsumer = UserContext.Consumer;

export { UserContext, UserContextProvider, UserContextConsumer };
