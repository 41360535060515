import React, { Fragment } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { filter } from "lodash";

import { processDataForStatistics } from './helpers';

import './index.css';

const SummaryCard = ({ moleculeLabel, statistics }) => {
  const visibleStatistics = filter(statistics, ({ show }) => show);

  return (
    <Col xs={4} className="mb-4">
      <Card xs={12}>
        <CardBody>
          <div className="statistics-summary-card">
            <div className="statistics-summary-card-label">{moleculeLabel}</div>
            <div className="statistics-summary-card-data">
              {visibleStatistics.map(({ label, value }) => {
                return (
                  <div className="data-item-container" key={label}>
                    <div className="data-item">
                      <div className="data-label">{label}</div>
                    </div>
                    <div className="data-item">
                      <div className="data-value">{value}</div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  )
}

const Statistics = ({ data, isLoading }) => {
  if (isLoading) {
    return (
      <div>Loading...</div>
    );
  }

  const { datasets } = processDataForStatistics(data);

  return (
    <Row>
      {datasets.map(({ moleculeLabel, statistics }) => {
        return (
          <SummaryCard
            moleculeLabel={moleculeLabel}
            statistics={statistics}
          />
        )
      })}
    </Row>
  );
}

export default Statistics;
