import React, { Fragment, useState, useEffect, useContext } from "react";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import { find, isEmpty, last, keys } from 'lodash';
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input
} from 'reactstrap';

import { v1 as uniqueId } from 'uuid';
import Joi from 'joi';

import { saveDefinition } from '../../../services/api';
import { formMolecules, availableMolecules } from '../../../services/form-builder';
import { processOrganism, defaultKeys } from '../../../services/logger';
import { parseId } from '../../../utils/helpers';
import { FIRST_ITEM, ONE_SECOND } from '../../../utils/constants';
import { findOptionalValue } from '../../../utils/helpers';

import Loading from "../../loading";
import Molecule from '../../molecule';
import Atom from '../../atom';
import TagsSelector from '../../tags-selector';

import BreadCrumb from '../../breadcrumb';

import { UserContext } from '../../../contexts/user'

import './index.css';

const getDefaultOrganism = () => ({
  id: `organism-${uniqueId()}`,
  name: '',
  molecules: [
    formMolecules['dateMolecule'].build(
      defaultKeys.DATE,
      {
        prompt: 'Select Date'
      }
    )
  ],
  tags: []
});

const organismSchema = Joi.object({
  id: Joi.string(),
  name: Joi.string().max(50).required().error(errors => {
    errors.forEach(err => {
      switch (err.code) {
        case "string.empty":
          err.message = `Please enter a name for your questionnaire.`;
          break;
        default:
          break;
      }
    });
    return errors
  }),
  molecules: Joi.array().items(Joi.object()),
  tags: Joi.array().items(Joi.string())
});

const atomSchema = Joi.object({
  id: Joi.string(),
  label: Joi.string(),
  type: Joi.string(),
  placeholder: Joi.string(),
  value: Joi.when('required', [
    { is: true, then: Joi.string().required().error(errors => {
      errors.forEach(err => {
        switch (err.code) {
          case "string.empty":
            err.message = `${err.state.ancestors[0].label}s cannot be empty.`;
            break;
          default:
            break;
        }
      });
      return errors
    })},
    { is: false, then: Joi.string().optional() }
  ]),
  dataLabel: Joi.boolean().optional(),
  required: Joi.boolean().optional(),
  info: Joi.string().optional(),
  options: Joi.array().optional()
})

const QuestionnaireName = ({ value, handleChange, isError }) => (
  <Row className="questionnaire-name mb-2">
    <Col xs={1} className="define-icon g-0">
      <i className="bi bi-lightbulb"></i>
    </Col>
    <Col xs={11}>
      <Input
        type="text"
        id="formName"
        placeholder="Name Your Questionnaire (eg. 'Daily Check-In')"
        value={value}
        onChange={handleChange}
        className={`questionnaire-name-input ${isError ? 'form-error' : ''}`}
      />
    </Col>
  </Row>
)

const AvailableFields = ({ availableFields, handleClickAddField }) => {
  return (
    <Row className="available-fields mb-2">
      <Col xs={1} className="define-icon g-0">
        <i className="bi bi-node-plus"></i>
      </Col>
      <Col xs={11}>
        <div className="available-fields-options">
          {availableFields.map(id => {
            const moleculeId = parseId(id);
            const { label, display: { icon, color } } = formMolecules[moleculeId].build();

            return (
              <div
                onClick={() => handleClickAddField(moleculeId)}
                className="available-fields-option"
                key={id}
              >
                <i className={`bi bi-${icon}`}></i>
                {label}
              </div>
            );
          })}
        </div>
      </Col>
    </Row>
  )
}

const CustomizeFields = ({
  hasMolecules,
  organism,
  selectedMoleculeToCustomize,
  handleClickCustomizeMolecule,
  handleChangeAtom,
  errorFieldMapping
}) => {
  return (
    <div className="molecule-editor">
      <p className="text-center p-2 help-header"><i className="bi bi-pencil-square mr-2"></i>Customize Fields</p>
      <Row>
        <Col xs={12}>
          <div className="molecule-editor-customizer">
            {hasMolecules && organism.molecules.map(molecule => {
              if (selectedMoleculeToCustomize === molecule.id) {
                return (
                  <div className="molecule" key={molecule.id}>
                    <table>
                      <thead>
                        <tr>
                          <th className="molecule-label text-center h3" colSpan={2}>
                            <i className={`bi bi-${molecule.display.icon}`}></i>{' '}
                            {molecule.label} Field
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {molecule.atoms.map(atom => {
                          return (
                            <Atom
                              atom={atom}
                              numAtoms={molecule.atoms.length}
                              moleculeId={molecule.id}
                              onChangeAtom={handleChangeAtom}
                              isError={errorFieldMapping[atom.id]}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                );
              }
            })}
          </div>
        </Col>
      </Row>
    </div>
  );
}

const FormPreview = ({
  hasMolecules,
  organism,
  formFields,
  handleChangeFormField,
  handleSelectFormField,
  handleClickDuplicate,
  handleClickDelete,
  selectedFormField,
  errorFieldMapping
}) => {

  if (hasMolecules) {
    return (
      <Row className="questionnaire-name mb-2">
        <p className="text-center mt-2 help-header"><i className="bi bi-eye mr-2"></i>Preview Your Questionnaire</p>
        <Col xs={1} className="define-icon g-0">
          <i className="bi bi-clipboard"></i>
        </Col>
        <Col xs={11}>
          <div className="form-preview">
            {formFields.length > 0 && formFields.map(formField => {
              const moleculeToDuplicate = find(organism.molecules, { id: formField.id })

              return (
                <div
                  key={formField.id}
                  className={
                    `form-preview-field
                    ${formField.id === selectedFormField ? ' selected' : ''}
                    ${errorFieldMapping[formField.id] ? ' form-error' : ''}
                    `
                  }
                  onClick={() => handleSelectFormField(formField.id)}
                >
                  <div className="form-preview-field-actions">
                    <div className="form-preview-field-action duplicate" onClick={() => handleClickDuplicate({ moleculeToDuplicate })}>
                      <i className="bi bi-files"></i>
                    </div>
                    <div className="form-preview-field-action delete" onClick={() => handleClickDelete({ moleculeId: formField.id })}>
                      <i className="bi bi-trash"></i>
                    </div>
                  </div>
                  <Molecule
                    formField={formField}
                    onChangeFormField={handleChangeFormField}
                  />
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    )
  } else {
    return ('')
  }
}

const SaveButton = ({
  handleClick,
  hasErrors,
  isDirty,
  isSaving,
  errors
}) => {
  return (
    <Row className="questionnaire-name mb-2">
      <Col xs={1} className="define-icon g-0">
        <i className="bi bi-file-check"></i>
      </Col>
      <Col xs={11}>
        <Button
          onClick={handleClick}
          color={hasErrors ? 'danger' : 'success'}
          className="mr-2 mb-2"
          outline
          disabled={!isDirty || hasErrors || isSaving}
        >
          {hasErrors ? errors[FIRST_ITEM] : (isSaving ? 'Saving...' : 'Save')}
        </Button>
      </Col>
    </Row>
  )
}

const Define = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { userState, dispatchUser } = useContext(UserContext);

  const { tags: availableTags, isLoading: isUserTagsLoading } = userState;

  const [isDirty, setIsDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [organism, setOrganism] = useState(getDefaultOrganism());
  const [errors, setErrors] = useState([]);
  const [errorFieldMapping, setErrorFieldMapping] = useState({});
  const [selectedMoleculeToCustomize, setSelectedMoleculeToCustomize] = useState(
    organism.molecules.length > 0 && organism.molecules[FIRST_ITEM].id
  );
  const [moleculeCount, setMoleculeCount] = useState(organism.molecules.length);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  const validateOrganism = organismToValidate => {
    const { error: organismErrors } = organismSchema.validate(organismToValidate);

    const atomErrors = []
    const moleculeErrorFieldMapping = {}

    organismToValidate.molecules.forEach(molecule => {
      molecule.atoms.forEach(atom => {
        const { error } = atomSchema.validate(atom);

        if (error) {
          moleculeErrorFieldMapping[molecule.id] = true
          atomErrors.push(error);
        }
      })
    })

    const updatedErrors = [
      ...organismErrors ? organismErrors.details.map(({ message }) => message) : [],
      ...atomErrors.map(({ message }) => message)
    ]

    const atomErrorFieldMapping = atomErrors.reduce((mapping, atomError) => {
      mapping[atomError._original.id] = true;

      return mapping;
    }, {})


    const organismErrorFieldMapping = organismErrors ? {
      [organismErrors._original.id]: true
    } : {}

    const updatedErrorFieldMapping = {
      ...atomErrorFieldMapping,
      ...organismErrorFieldMapping,
      ...moleculeErrorFieldMapping
    }

    return {
      updatedErrorFieldMapping,
      updatedErrors
    }
  }

  const [formFields, setFormFields] = useState(processOrganism(organism));

  useEffect(() => {
    setFormFields(processOrganism(organism));

    if (organism.molecules.length === 0) {
      setSelectedMoleculeToCustomize(null);
    }
  }, [organism]);

  useEffect(() => {
    setSelectedMoleculeToCustomize(last(organism.molecules).id);
  }, [moleculeCount]);

  const handleChangeText = event => {
    if (!isDirty) {
      setIsDirty(true);
      setErrors([]);
      setErrorFieldMapping({});
    }

    const updatedOrganism = {
      ...organism,
      name: event.target.value
    }

    setOrganism(updatedOrganism);
  }

  const handleClickAddMolecule = moleculeId => {
    const updatedOrganism = {
      ...organism,
      molecules: [
        ...organism.molecules,
        formMolecules[moleculeId].build(uniqueId())
      ]
    }

    setOrganism(updatedOrganism);
    setMoleculeCount(updatedOrganism.molecules.length)
  }

  const handleChangeAtom = ({ atomId, moleculeId, event }) => {
    if (!isDirty) {
      setIsDirty(true);
      setErrors([]);
      setErrorFieldMapping({});
    }

    const updatedOrganism = {
      ...organism,
      molecules: organism.molecules.map(molecule => {
        if (molecule.id === moleculeId) {
          return {
            ...molecule,
            atoms: molecule.atoms.map(atom => {
              if (atom.id === atomId) {
                return {
                  ...atom,
                  value: event.target.value
                }
              }

              return atom;
            }),
            required: !findOptionalValue({ collection: molecule.atoms, defaultValue: false })
          }
        }

        return molecule;
      })
    }

    setOrganism(updatedOrganism);
  }

  const handleClickDelete = ({ moleculeId }) => {
    const updatedOrganism = {
      ...organism,
      molecules: organism.molecules.filter(molecule => {
        return molecule.id !== moleculeId;
      })
    }

    setOrganism(updatedOrganism);
  }

  const handleClickDuplicate = ({ moleculeToDuplicate }) => {
    const key = uniqueId();

    const updatedOrganism = {
      ...organism,
      molecules: [...organism.molecules, {
        ...moleculeToDuplicate,
        id: `${parseId(moleculeToDuplicate.id)}|${key}`,
        atoms: moleculeToDuplicate.atoms.map(atom => ({
          ...atom,
          id: `${parseId(atom.id)}|${key}`
        }))
      }]
    }

    setOrganism(updatedOrganism);
  }

  const handleChangeFormField = ({ formFieldId, value }) => {
    setFormFields(formFields.map(formField => {
      if (formField.id === formFieldId) {
        return {
          ...formField,
          value
        }
      }

      return formField;
    }));
  }

  const handleClickSave = async () => {
    setIsSaving(true);

    const { updatedErrorFieldMapping, updatedErrors } = validateOrganism(organism);

    if (keys(updatedErrorFieldMapping).length === 0 && updatedErrors.length === 0) {
      try {
        const token = await getAccessTokenSilently();
        const { data } = await saveDefinition(token, organism);

        if (data?.success) {
          setTimeout(
            () => {
              dispatchUser({
                type: 'SET_TAGS',
                payload: {
                  tags: data.updatedTags
                }
              });
              setIsSaving(false);
              setIsDirty(false);
              setOrganism(getDefaultOrganism());
              scrollToTop();
            },
            ONE_SECOND
          );
        }
      } catch (error) {
        setIsSaving(false);
        setIsDirty(false);
        setErrors({ save: error.message })
      }
    } else {
      if (keys(updatedErrorFieldMapping).length > 0) {
        setErrorFieldMapping(updatedErrorFieldMapping)
      } else {
        setErrorFieldMapping({})
      }

      if (updatedErrors.length > 0) {
        setErrors(updatedErrors);
      } else {
        setErrors([]);
      }

      setIsDirty(false);
      setIsSaving(false);
    }
  }

  const handleClickCustomizeMolecule = moleculeId => {
    setSelectedMoleculeToCustomize(moleculeId)
  }

  const handleChangeTags = tags => {
    const updatedOrganism = {
      ...organism,
      tags
    }

    setOrganism(updatedOrganism);
  }

  const hasErrors = !isEmpty(errors);
  const hasMolecules = organism.molecules.length > 0;

  if (isSaving) {
    return <div className="p-4"><Loading/></div>
  }

  return (
    <Fragment>
      <BreadCrumb paths={
        [
          {
            name: 'Define',
            link: '/define'
          }
        ]
      }/>
      <section className="pt-4 pb-4">
        <div className="container-fluid">
          <Row>
            <Col xs={'7'}>
              <h4>Define Your Progress Metrics</h4>
              <p className="text-muted text-small mb-4">Build a personal questionnaire around any topic you want to track or analyze. It could be around fitness, mental-health, diet, sleep, productivity, etc.</p>
              <Form>
                <FormGroup row>
                  <Col sm={12}>
                    <QuestionnaireName
                      value={organism.name}
                      handleChange={handleChangeText}
                      isError={errorFieldMapping[organism.id]}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={12}>
                    <FormPreview
                      hasMolecules={hasMolecules}
                      organism={organism}
                      formFields={formFields}
                      handleChangeFormField={handleChangeFormField}
                      handleSelectFormField={handleClickCustomizeMolecule}
                      selectedFormField={selectedMoleculeToCustomize}
                      handleClickDuplicate={handleClickDuplicate}
                      handleClickDelete={handleClickDelete}
                      errorFieldMapping={errorFieldMapping}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={12}>
                    <AvailableFields
                      availableFields={availableMolecules}
                      handleClickAddField={handleClickAddMolecule}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={12}>
                    <SaveButton
                      hasErrors={hasErrors}
                      handleClick={handleClickSave}
                      isSaving={isSaving}
                      isDirty={isDirty}
                      errors={errors}
                     />
                  </Col>
                </FormGroup>
              </Form>
            </Col>
            <Col xs={'5'}>
              <Row>
                <Col xs={12}>
                  <CustomizeFields
                    hasMolecules={hasMolecules}
                    organism={organism}
                    selectedMoleculeToCustomize={selectedMoleculeToCustomize}
                    handleClickCustomizeMolecule={handleClickCustomizeMolecule}
                    handleChangeAtom={handleChangeAtom}
                    errorFieldMapping={errorFieldMapping}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <TagsSelector
                    selectedTags={organism.tags}
                    availableTags={availableTags}
                    isLoading={isUserTagsLoading}
                    onChangeTags={handleChangeTags}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </section>
    </Fragment>
  );
};

export default withAuthenticationRequired(Define, {
  onRedirecting: () => <Loading />,
});
