import { each } from 'lodash';
import { parseId } from '../../utils/helpers';
import { formMolecules } from '../form-builder';

export const defaultKeys = {
  'DATE': 'default-date'
}

export const processOrganism = organism => {
  const output = [];

  each(organism.molecules, molecule => {
    const moleculeId = parseId(molecule.id);

    output.push(formMolecules[moleculeId].process(molecule));
  });

  return output;
}

export const processOrganismWithLogData = ({ definition, logData }) => {
  const processedOrganism = processOrganism(definition);
  const { data, date } = logData;

  const dataMapping = {
    ...data,
    'dateMolecule|default-date': new Date(date)
  }

  return processedOrganism.map(molecule => ({
    ...molecule, value: dataMapping[molecule.id]
  }));
}

const parseDefaults = id => id.includes('default')
  ? id.split('default-')[1]
  : id;

export const buildPayloadForLog = ({ definition, formFields, selectedTags, editId }) => {
  if (definition && formFields) {
    return {
      definitionId: definition._id,
      ...formFields.reduce((mapping, formField) => {
        const moleculeId = parseId(formField.id);

        mapping[parseDefaults(formField.id)] = formMolecules[moleculeId].format.raw(formField.value);

        return mapping;
      }, {}),
      tags: selectedTags,
      editId
    }
  }

  return {}
}
