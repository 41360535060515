import React, { Fragment, useState, useEffect, useContext } from 'react';
import { find } from 'lodash';
import { useAuth0 } from "@auth0/auth0-react";
import { startOfDay, endOfDay, addDays, subDays } from 'date-fns';
import { DateRangePicker, MultiCascader } from 'rsuite';
import { Row, Col } from 'reactstrap';

import { fetchDefinitions } from '../../services/api';
import { QueryContext } from '../../contexts/query';
import Loading from '../loading';

import { ONE_SECOND } from '../../utils/constants';
import { findPromptValue } from '../../utils/helpers';

const buildQuery = ({ nodes: molecules, range }) => ({ molecules, range });

const ranges = [
  {
    label: 'last7Days',
    value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())]
  },
  {
    label: 'last30Days',
    value: [startOfDay(subDays(new Date(), 29)), endOfDay(new Date())]
  },
];

const defaultStartDate = subDays(new Date(), 30)
const defaultEndDate = new Date()

const MoleculesSelector = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { dispatchQuery } = useContext(QueryContext);

  const [isLoading, setIsLoading] = useState(true);
  const [definitions, setDefinitions] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [dateRange, setDateRange] = useState([
    defaultStartDate,
    defaultEndDate
  ]);

  useEffect(() => {
    const getDefinitions = async () => {
      const token = await getAccessTokenSilently();
      const { data: { definitions } } = await fetchDefinitions(token);

      const formattedDefinitions = definitions.map(definition => ({
        label: definition.name,
        value: `all:${definition._id}`,
        children: definition.molecules.filter(({ id }) => !id.includes('default-'))
          .map(molecule => {
            return {
              label: `${findPromptValue({ collection: molecule.atoms, defaultValue: molecule.label })} - ${definition.name}`,
              value: `${definition._id}:${molecule.id}`
            }
          })
      }));

      setTimeout(() => {
        setDefinitions(formattedDefinitions);
        setIsLoading(false);
      }, ONE_SECOND);
    }

    getDefinitions();
  }, []);

  useEffect(() => {
    const start = dateRange[0]
    const end = dateRange[1]

    dispatchQuery({
      type: 'UPDATE_QUERY',
      payload: buildQuery({
        nodes: selectedNodes,
        range: { start, end }
      })
    })
  }, [dateRange, selectedNodes])

  const onChange = newSelectedNodes => {
    setSelectedNodes(newSelectedNodes);
  }

  if (isLoading) return (
    <Loading />
  );

  return (
    <Fragment>
      <Row>
        <Col xs={6}>
          <DateRangePicker
            block
            onChange={updatedRange => setDateRange(updatedRange)}
            ranges={ranges}
            placeholder="Select Date Range"
            value={dateRange}
            size="lg"
          />
        </Col>
        <Col xs={6}>
          <MultiCascader
            block
            placeholder="Select Definitions"
            data={definitions}
            onChange={onChange}
            menuWidth={200}
            size="lg"
          />
        </Col>
      </Row>
    </Fragment>
  );
}

export default MoleculesSelector;
