import { chain, find, each, keys, map, filter, includes } from 'lodash';

const DERIVED_METRICS = [
  'tags|derived',
  'gap|derived'
]

export const byDerivedDefinitionId = (x, y) => x.id === 'derived' ? -1 : y.id === 'derived' ? 1 : 0
export const byDerivedMoleculeId = (x, y) => includes(DERIVED_METRICS, x) ? -1 : y === includes(DERIVED_METRICS, y) ? 1 : 0

export const processDataForDataSheet = ({ stats, definitions }) => {
  const allDates = map(stats, ({ date }) => date)

  const dataMappingBySelectedDates = stats.reduce((mapping, stat) => {
    let statDataByMolecule = {}

    each(keys(stat.data), key => {
      statDataByMolecule = {
        ...statDataByMolecule,
        ...stat.data[key]
      }
    })

    mapping[stat.date] = {
      ...statDataByMolecule
    };

    return mapping;
  }, {});

  const allMolecules = chain(definitions)
    .values()
    .map(({ molecules, name, _id: id }) => {
      return map(molecules, molecule => ({
        ...molecule, definition: { name, id }
      }))
    })
    .flatten()
    .filter(({ id }) => !id.includes('default'))
    .reduce((mapping, molecule) => {
      mapping[molecule.id] = {
        molecule,
        label: find(molecule.atoms, { 'label': 'Label' })?.value,
        prompt: find(molecule.atoms, { 'label': 'Prompt' })?.value
      }

      return mapping
    }, {})
    .value()

  const selectedMolecules = chain(stats)
    .map(stats => {
      const molecules = chain(stats.data)
        .keys()
        .map(key => keys(stats.data[key]))
        .value()

      return molecules
    })
    .flattenDeep()
    .uniq()
    .reduce((mapping, key) => {
      mapping[key] = allMolecules[key];

      return mapping
    }, {})
    .value()

  const selectedMoleculeIds = keys(selectedMolecules).sort(byDerivedMoleculeId)

  const selectedDefinitions = map(selectedMoleculeIds, key => (
    selectedMolecules[key].molecule.definition
  )).sort(byDerivedDefinitionId)

  const parentDefinitions = chain(selectedDefinitions)
    .map((selectedDefinition, index) => {
      const colSpan = filter(
        selectedDefinitions,
        ({ id }) => id === selectedDefinition.id
      ).length

      return {
        ...selectedDefinition,
        colSpan,
        realIndex: index + 1
      }
    })
    .uniqBy('id')
    .value()

  const parentHeaderRow = [
    {
      readOnly: true,
      value: "",
      disableEvents: true,
      className: 'parent-header-cell empty-cell'
    },
    ...map(parentDefinitions, ({ name: value, colSpan, realIndex }) => {
      return {
        readOnly: true,
        value,
        colSpan,
        realIndex,
        className: 'parent-header-cell'
      }
    })
  ]

  const headerRow = [
    {
      readOnly: true,
      value: "",
      disableEvents: true,
      className: 'header-cell empty-cell'
    },
    ...map(selectedMoleculeIds, key => {
      const value = selectedMolecules[key].label

      return {
        readOnly: true,
        value,
        className: 'header-cell'
      }
    })
  ]

  const dataRows = map(allDates, (date, index) => {
    const rowOutput = [
      {
        readOnly: true,
        value: date,
        className: 'date-cell'
      }
    ]

    each(selectedMoleculeIds, moleculeId => {
      const value = dataMappingBySelectedDates[date][moleculeId]?.value || ''

      rowOutput.push({ value, className: 'data-cell', readOnly: true })
    })

    return rowOutput;
  })

  let rows = [parentHeaderRow, headerRow, ...dataRows];

  rows = filter(rows, row => {
    if (row.length < 2) {
      return false
    }

    const hasNonEmptyItems = chain(row)
      .tail()
      .filter(({ value }) => value !== '')
      .value().length > 0

    return hasNonEmptyItems
  });

  const lastRow = map(rows[rows.length - 1], column => ({
    ...column,
    className: column.className ? `${column.className} bottom-row` : 'bottom-row'
  }))

  rows[rows.length - 1] = lastRow

  return { rows }
}

export const getSelectionByMouseEvents = ({ processedData, start, end }) => {
  const rowCount = processedData.rows.length;
  const columnCount = processedData.rows[0].length;

  const endedOnDateColumn = end.j === 0;
  const endedOnParentHeaderRow = end.i === 0;
  const endedOnHeaderRow = end.i === 1;
  const draggedSelection = (start.i !== end.i) || (start.j !== end.j);

 if (draggedSelection) {
    if (endedOnDateColumn) {
      return {
        start: { i: start.i, j: 1 },
        end:   { i: end.i, j: columnCount }
      }
    } else if (endedOnParentHeaderRow) {
      const { colSpan, realIndex } = processedData.rows[end.i][end.j];

      return {
        start: { i: 2, j: start.j },
        end:   { i: rowCount, j: realIndex + colSpan - 1 }
      }
    } else if (endedOnHeaderRow) {
      return {
        start: { i: 2, j: start.j },
        end:   { i: rowCount, j: end.j }
      }
    } else {
      return { start, end }
    }
  } else {
    if (endedOnDateColumn) {
      return {
        start: { i: start.i, j: start.j + 1 },
        end:   { i: start.i, j: columnCount }
      }
    } else if (endedOnParentHeaderRow) {
      const { colSpan, realIndex } = processedData.rows[start.i][start.j];

      return {
        start: { i: start.i + 2, j: realIndex },
        end:   { i: rowCount, j: realIndex + colSpan - 1 }
      }
    } else if (endedOnHeaderRow) {
      return {
        start: { i: start.i + 1, j: start.j },
        end:   { i: rowCount, j: start.j }
      }
    } else {
      return { start, end }
    }
  }
}