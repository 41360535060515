import React, { useState } from 'react';
import { Row, Col, Badge, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { find } from 'lodash';
import { format } from 'date-fns';

import Loading from '../loading';

import { findLabelValue } from '../../utils/helpers';

import './index.css';

const DefinitionsSelector = ({ definitions, isLoading, onConfirmDeleteDefinition }) => {
  const [toDelete, setToDelete] = useState({});

  const handleClickDelete = definitionId => {
    if (!toDelete[definitionId]) {
      setToDelete({
        ...toDelete,
        [definitionId]: true
      });
    }
  }

  const handleConfirmDelete = definitionId => {
    onConfirmDeleteDefinition(definitionId);
  }

  const handleCancelDelete = () => {
    setToDelete({})
  }

  if (isLoading) {
    return (
      <Loading />
    )
  }

  return (
    <Row>
      <Col>
        <div className="definition-selections">
          {definitions.map(definition => {
            return (
              <div className="definition-selection">
                <div className="definition-selection-actions">
                  <div
                    className="definition-selection-action"
                    onClick={() => handleClickDelete(definition._id)}
                  >
                    {toDelete[definition._id] ? (
                      <span>
                        <i onClick={handleCancelDelete} className="bi bi-x-circle confirm-delete"></i>
                        <i onClick={() => handleConfirmDelete(definition._id)} className="bi bi-check-circle cancel-delete"></i>
                      </span>
                    ) : (
                      <i className="bi bi-trash select-delete"></i>
                    )}
                  </div>
                </div>
                <div className="definition-selection-name">{definition.name}</div>
                <div className="definition-selection-molecules">
                  {definition.molecules.map(molecule => {
                    return (
                      <Badge
                        className="definition-selection-molecule"
                        pill
                        style={{
                          borderColor: molecule.display.color,
                          color: molecule.display.color
                        }}
                      >
                        <i className={`bi bi-${molecule.display.icon}`}></i>
                        {' '}{findLabelValue({ collection: molecule.atoms, defaultValue: molecule.label })}
                      </Badge>
                    )
                  })}
                </div>
                <div className="definition-selection-meta">
                  <i className="bi bi-pencil"></i>{' '}{format(new Date(definition.createdAt), "MMM do, y")}
                </div>
                <Link
                  key={definition._id}
                  to={`/log/${definition._id}`}
                  className="definition-selection-link"
                >
                  <i className="bi bi-journal-plus"></i>
                </Link>
              </div>
            );
          })}
        </div>
        <Row>
          <Col>
            <Link
              to={`/define`}
            >
              <Button
                outline
                size="lg"
                className="create-questionnaire-button"
              >
                <i className="bi bi-journal-plus"></i> Create A Questionnaire
              </Button>
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default DefinitionsSelector;
