import statsLite from "stats-lite";
import { each, isEmpty, keys, map, find } from "lodash";

import { formMoleculesWithDerivations as formMolecules } from "../../services/form-builder";
import { parseId, findLabelValue } from "../../utils/helpers";

export const getStatistics = ({ data, counts, entries, moleculeId, moleculeInfo }) => {
  return formMolecules[parseId(moleculeId)].analyze({ data, counts, entries, molecule: moleculeInfo });
}

export const processDataForStatistics = ({ stats, definitions }) => {
  const datasetMapping = {}

  each(stats, ({ date, data }) => {
    if (!isEmpty(data)) {
      const definitionIds = keys(data);

      each(definitionIds, definitionId => {
        const moleculeIds = keys(data[definitionId])

        each(moleculeIds, moleculeId => {
          const { value, count, entries } = data[definitionId][moleculeId];

          const moleculeInfo = find(definitions[definitionId].molecules, { id: moleculeId });
          const moleculeLabel = findLabelValue({
            collection: moleculeInfo.atoms,
            defaultValue: moleculeInfo.label
          });

          const formattedValue = formMolecules[parseId(moleculeId)]
            .format.data({ data: { value, count, entries }, molecule: moleculeInfo })

          if (datasetMapping[moleculeId]) {
            datasetMapping[moleculeId] = {
              ...datasetMapping[moleculeId],
              data: [...datasetMapping[moleculeId].data, formattedValue],
              entries: [...datasetMapping[moleculeId].entries, entries],
              counts: [...datasetMapping[moleculeId].counts, count]
            }
          } else {
            datasetMapping[moleculeId] = {
              moleculeId,
              moleculeLabel,
              moleculeInfo,
              data: [formattedValue],
              entries: [entries],
              counts: [count]
            }
          }
        })
      })
    }
  });

  const datasets = map(keys(datasetMapping), datasetKey => ({
    ...datasetMapping[datasetKey]
  }));

  const output = {
    datasets: datasets.map(dataset => ({
      ...dataset,
      statistics: getStatistics({
        data: dataset.data,
        counts: dataset.counts,
        entries: dataset.entries,
        moleculeId: dataset.moleculeId,
        moleculeInfo: dataset.moleculeInfo
      })
    }))
  }

   return output
}
