import { find } from 'lodash';

import {
  ID_DELIMITER,
  FIRST_ITEM,
  OPTIONAL_FIELD_STR,
  LABEL_FIELD_STR,
  PROMPT_FIELD_STR,
  COUNTABLE_FIELD_STR,
  MEASUREMENT_FIELD_STR
} from './constants';

export const parseId = rawId => rawId.split(ID_DELIMITER)[FIRST_ITEM];

export const formatDate = date => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

export const findOptionalValue = ({ collection, defaultValue = false }) => {
  return find(collection, { label: OPTIONAL_FIELD_STR })?.value || defaultValue
}

export const findLabelValue = ({ collection, defaultValue = '' }) => {
  return find(collection, { label: LABEL_FIELD_STR })?.value || defaultValue
}

export const findPromptValue = ({ collection, defaultValue = '' }) => {
  return find(collection, { label: PROMPT_FIELD_STR })?.value || defaultValue
}

export const findCountableValue = ({ collection, defaultValue = '' }) => {
  return find(collection, { label: COUNTABLE_FIELD_STR })?.value || defaultValue
}

export const findMeasurementValue = ({ collection, defaultValue = '' }) => {
  return find(collection, { label: MEASUREMENT_FIELD_STR })?.value || defaultValue
}
