import { each, keys, sum, uniq, flatten } from 'lodash';
import statsLite from "stats-lite";
import Sentiment from "sentiment";

import { findCountableValue, findMeasurementValue } from "../../utils/helpers";

import {
  ANALYZE_SUM_LABEL,
  ANALYZE_MEAN_LABEL,
  ANALYZE_VARIANCE_LABEL,
  ANALYZE_STDV_LABEL,
  ANALYZE_COUNT_LABEL,
  MEASUREMENT_SENTIMENT_LABEL,
  MEASUREMENT_LENGTH_OF_TEXT_LABEL,
  MEASUREMENT_EXISTENCE_LABEL,
  MEASUREMENT_LAST_ENTRY_LABEL,
  MEASUREMENT_COUNTABLE_LABEL
} from "./constants";

const sentiment = new Sentiment();

const derivedMolecules = {
  gap: {
    format: {
      data: ({ data, molecule }) => data.value,
      display: ({ data, molecule }) => data.value
    },
    analyze: ({ data, counts, entries, molecule }) => {
      return [
        {
          label: 'Average',
          value: statsLite.mean(data).toFixed(2),
          show: true
        },
        {
          label: 'Longest',
          value: Math.max(...data),
          show: true
        },
        {
          label: 'Total',
          value: sum(counts),
          show: true
        }
      ]
    }
  },
  tags: {
    format: {
      data: ({ data, molecule }) => data.value,
      display: ({ data, molecule }) => data.value
    },
    analyze: ({ data, counts, entries, molecule }) => {
      return [
        {
          label: 'Unique',
          value: uniq(flatten(entries)).length,
          show: true
        }
      ]
    }
  }
}

export const formMolecules = {
  textMolecule: {
    build: key => ({
      id: `textMolecule|${key}`,
      label: 'Text',
      required: false,
      display: {
        color: '#3c6382',
        icon: 'input-cursor-text'
      },
      atoms: [
        {
          id: `labelAtom|${key}`,
          label: 'Label',
          type: 'text',
          placeholder: 'Enter Label',
          value: '',
          dataLabel: true,
          required: true,
          info: 'This will be used as the data label for charts, tables and graphs.'
        },
        {
          id: `promptAtom|${key}`,
          label: 'Prompt',
          type: 'text',
          placeholder: 'Enter Prompt',
          value: '',
          required: true,
        },
        {
          id: `placeholderAtom|${key}`,
          label: 'Placeholder',
          type: 'text',
          placeholder: 'Enter Placeholder',
          value: ''
        },
        {
          id: `characterLimitAtom|${key}`,
          label: 'Character Limit',
          type: 'number',
          placeholder: 'Enter Character Limit',
          value: 100
        },
        {
          id: `measurementAtom|${key}`,
          label: 'Measurement',
          type: 'selection',
          options: [
            MEASUREMENT_SENTIMENT_LABEL,
            MEASUREMENT_LENGTH_OF_TEXT_LABEL,
            MEASUREMENT_EXISTENCE_LABEL
          ],
          placeholder: 'Measurement',
          value: MEASUREMENT_SENTIMENT_LABEL
        },
        {
          id: `optionalAtom|${key}`,
          label: 'Optional',
          type: 'checkbox',
          placeholder: 'Optional',
          value: false
        }
      ]
    }),
    process: molecule => {
      const output = {
        id: molecule.id,
        type: 'text',
        value: '',
        required: molecule.required
      };

      each(molecule.atoms, ({ id, value }) => {
        if (id.includes('promptAtom')) {
          output.label = value;
        }

        if (id.includes('placeholderAtom')) {
          output.placeholder = value;
        }

        if (id.includes('characterLimitAtom')) {
          output.maxLength = value;
        }
      });

      return output;
    },
    format: {
      raw: value => value === null ? '' : String(value),
      data: ({ data: { value, count, entries }, molecule }) => {
        if (value === null) {
          return 0;
        }

        const measurement = findMeasurementValue({
          collection: molecule.atoms,
          defaultValue: MEASUREMENT_EXISTENCE_LABEL
        });

        if (measurement === MEASUREMENT_SENTIMENT_LABEL) {
          const { score } = sentiment.analyze(value);

          return score;
        }

        if (measurement === MEASUREMENT_LENGTH_OF_TEXT_LABEL) {
          return value.length;
        }

        return value.length > 0 ? 1 : 0;
      },
      display: ({ value, molecule }) => value === null ? '' : String(value)
    },
    analyze: ({ data, counts, entries, molecule }) => {
      return [
        {
          label: ANALYZE_SUM_LABEL,
          value: statsLite.sum(data),
          show: findCountableValue({ collection: molecule.atoms, defaultValue: false })
        },
        {
          label: ANALYZE_MEAN_LABEL,
          value: statsLite.mean(data).toFixed(2),
          show: true
        },
        {
          label: ANALYZE_VARIANCE_LABEL,
          value: Number(statsLite.variance(data).toFixed(2)),
          show: false
        },
        {
          label: ANALYZE_STDV_LABEL,
          value: Number(statsLite.stdev(data).toFixed(2)),
          show: false
        },
        {
          label: ANALYZE_COUNT_LABEL,
          value: sum(counts),
          show: true
        }
      ]
    }
  },
  numberMolecule: {
    build: key => ({
      id: `numberMolecule|${key}`,
      label: 'Number',
      required: false,
      display: {
        color: '#079992',
        icon: '123'
      },
      atoms: [
        {
          id: `labelAtom|${key}`,
          label: 'Label',
          type: 'text',
          placeholder: 'Enter Label',
          value: '',
          dataLabel: true,
          required: true,
          info: 'This will be used as the data label for charts, tables and graphs.'
        },
        {
          id: `promptAtom|${key}`,
          label: 'Prompt',
          type: 'text',
          placeholder: 'Enter Prompt',
          value: '',
          required: true,
        },
        {
          id: `minAtom|${key}`,
          label: 'Min',
          type: 'number',
          placeholder: 'Enter Min',
          value: String(0)
        },
        {
          id: `maxAtom|${key}`,
          label: 'Max',
          type: 'number',
          placeholder: 'Enter Max',
          value: String(100)
        },
        {
          id: `measurementAtom|${key}`,
          label: 'Measurement',
          type: 'selection',
          options: [
            MEASUREMENT_LAST_ENTRY_LABEL,
            MEASUREMENT_COUNTABLE_LABEL
          ],
          placeholder: 'Measurement',
          value: MEASUREMENT_LAST_ENTRY_LABEL
        },
        {
          id: `optionalAtom|${key}`,
          label: 'Optional',
          type: 'checkbox',
          placeholder: 'Optional',
          value: false
        },
      ]
    }),
    process: molecule => {
      const output = {
        id: molecule.id,
        type: 'number',
        value: null,
        required: molecule.required
      };

      each(molecule.atoms, ({ id, value }) => {
        if (id.includes('promptAtom')) {
          output.label = value;
        }

        if (id.includes('minAtom')) {
          output.min = value;
        }

        if (id.includes('maxAtom')) {
          output.max = value;
        }
      });

      return output;
    },
    format: {
      raw: value => value === null ? null : Number(value),
      data: ({ data: { value, count, entries }, molecule }) => value === null ? null : Number(value),
      display: ({ value, molecule }) => value === null ? null : Number(value)
    },
    analyze: ({ data, counts, entries, molecule }) => {
      return [
        {
          label: ANALYZE_SUM_LABEL,
          value: statsLite.sum(data),
          show: findCountableValue({ collection: molecule.atoms, defaultValue: false })
        },
        {
          label: ANALYZE_MEAN_LABEL,
          value: statsLite.mean(data).toFixed(2),
          show: true
        },
        {
          label: ANALYZE_VARIANCE_LABEL,
          value: Number(statsLite.variance(data).toFixed(2)),
          show: false
        },
        {
          label: ANALYZE_STDV_LABEL,
          value: Number(statsLite.stdev(data).toFixed(2)),
          show: false
        },
        {
          label: ANALYZE_COUNT_LABEL,
          value: sum(counts),
          show: true
        }
      ]
    }
  },
  rangeMolecule: {
    build: key => ({
      id: `rangeMolecule|${key}`,
      label: 'Range',
      required: false,
      display: {
        color: '#78e08f',
        icon: 'sliders'
      },
      atoms: [
        {
          id: `labelAtom|${key}`,
          label: 'Label',
          type: 'text',
          placeholder: 'Enter Label',
          value: '',
          dataLabel: true,
          required: true,
          info: 'This will be used as the data label for charts, tables and graphs.'
        },
        {
          id: `promptAtom|${key}`,
          label: 'Prompt',
          type: 'text',
          placeholder: 'Enter Prompt',
          value: '',
          required: true,
        },
        {
          id: `minAtom|${key}`,
          label: 'Min',
          type: 'number',
          placeholder: 'Enter Min',
          value: 1
        },
        {
          id: `maxAtom|${key}`,
          label: 'Max',
          type: 'number',
          placeholder: 'Enter Max',
          value: 5
        },
        {
          id: `measurementAtom|${key}`,
          label: 'Measurement',
          type: 'selection',
          options: [
            MEASUREMENT_LAST_ENTRY_LABEL,
            MEASUREMENT_COUNTABLE_LABEL
          ],
          placeholder: 'Measurement',
          value: MEASUREMENT_LAST_ENTRY_LABEL
        },
        {
          id: `optionalAtom|${key}`,
          label: 'Optional',
          type: 'checkbox',
          placeholder: 'Optional',
          value: false
        },
      ]
    }),
    process: molecule => {
      const output = {
        id: molecule.id,
        type: 'range',
        value: null,
        required: molecule.required
      };

      each(molecule.atoms, ({ id, value }) => {
        if (id.includes('promptAtom')) {
          output.label = value;
        }

        if (id.includes('minAtom')) {
          output.min = Number(value);
        }

        if (id.includes('maxAtom')) {
          output.max = Number(value);
        }
      });

      return output;
    },
    format: {
      raw: value => value === null ? null : Number(value),
      data: ({ data: { value, count, entries }, molecule }) => value === null ? null : Number(value),
      display: ({ value, molecule }) => value === null ? null : Number(value)
    },
    analyze: ({ data, counts, entries, molecule }) => {
      return [
        {
          label: ANALYZE_SUM_LABEL,
          value: statsLite.sum(data),
          show: findCountableValue({ collection: molecule.atoms, defaultValue: false })
        },
        {
          label: ANALYZE_MEAN_LABEL,
          value: statsLite.mean(data).toFixed(2),
          show: true
        },
        {
          label: ANALYZE_VARIANCE_LABEL,
          value: Number(statsLite.variance(data).toFixed(2)),
          show: false
        },
        {
          label: ANALYZE_STDV_LABEL,
          value: Number(statsLite.stdev(data).toFixed(2)),
          show: false
        },
        {
          label: ANALYZE_COUNT_LABEL,
          value: sum(counts),
          show: true
        }
      ]
    }
  },
  dateMolecule: {
    build: (key, options) => ({
      id: `dateMolecule|${key}`,
      label: 'Date',
      required: false,
      display: {
        color: '#e58e26',
        icon: 'calendar-plus'
      },
      atoms: [
        {
          id: `promptAtom|${key}`,
          label: 'Prompt',
          type: 'text',
          placeholder: 'Enter Prompt',
          value: options?.prompt || 'Select Date'
        },
        {
          id: `optionalAtom|${key}`,
          label: 'Optional',
          type: 'checkbox',
          placeholder: 'Optional',
          value: false
        }
      ]
    }),
    process: molecule => {
      const output = {
        id: molecule.id,
        value: new Date(),
        required: molecule.required
      };

      each(molecule.atoms, ({ id, value }) => {
        if (id.includes('promptAtom')) {
          output.label = value;
        }
      });

      return output;
    },
    format: {
      raw: value => value,
      data: ({ data: { value, count, entries }, molecule }) => value,
      display: ({ value, molecule }) => value
    },
    analyze: ({ data, counts, entries, molecule }) => {
      return [
        {
          label: ANALYZE_SUM_LABEL,
          value: statsLite.sum(data),
          show: findCountableValue({ collection: molecule.atoms, defaultValue: false })
        },
        {
          label: ANALYZE_MEAN_LABEL,
          value: statsLite.mean(data).toFixed(2),
          show: true
        },
        {
          label: ANALYZE_VARIANCE_LABEL,
          value: Number(statsLite.variance(data).toFixed(2)),
          show: false
        },
        {
          label: ANALYZE_STDV_LABEL,
          value: Number(statsLite.stdev(data).toFixed(2)),
          show: false
        },
        {
          label: ANALYZE_COUNT_LABEL,
          value: sum(counts),
          show: true
        }
      ]
    }
  },
  yesnoMolecule: {
    build: key => ({
      id: `yesnoMolecule|${key}`,
      label: 'Yes-No',
      required: false,
      display: {
        color: '#b71540',
        icon: 'question-square'
      },
      atoms: [
        {
          id: `labelAtom|${key}`,
          label: 'Label',
          type: 'text',
          placeholder: 'Enter Label',
          value: '',
          dataLabel: true,
          required: true,
          info: 'This will be used as the data label for charts, tables and graphs.'
        },
        {
          id: `promptAtom|${key}`,
          label: 'Prompt',
          type: 'text',
          placeholder: 'Enter Prompt',
          value: '',
          required: true,
        },
        {
          id: `measurementAtom|${key}`,
          label: 'Measurement',
          type: 'selection',
          options: [
            MEASUREMENT_LAST_ENTRY_LABEL,
            MEASUREMENT_COUNTABLE_LABEL
          ],
          placeholder: 'Measurement',
          value: MEASUREMENT_LAST_ENTRY_LABEL
        },
        {
          id: `optionalAtom|${key}`,
          label: 'Optional',
          type: 'checkbox',
          placeholder: 'Optional',
          value: false
        },
      ]
    }),
    process: molecule => {
      const output = {
        id: molecule.id,
        type: 'text',
        value: '',
        required: molecule.required
      };

      each(molecule.atoms, ({ id, value }) => {
        if (id.includes('promptAtom')) {
          output.label = value;
        }
      });

      return output;
    },
    format: {
      raw: value => value === null ? null : Number(value),
      data: ({ data: { value, count, entries }, molecule }) => value === null ? null : Number(value),
      display: ({ value, molecule }) => value === null ? null : Number(value)
    },
    analyze: ({ data, counts, entries, molecule }) => {
      return [
        {
          label: ANALYZE_SUM_LABEL,
          value: statsLite.sum(data),
          show: true
        },
        {
          label: ANALYZE_MEAN_LABEL,
          value: statsLite.mean(data).toFixed(2),
          show: true
        },
        {
          label: ANALYZE_VARIANCE_LABEL,
          value: Number(statsLite.variance(data).toFixed(2)),
          show: false
        },
        {
          label: ANALYZE_STDV_LABEL,
          value: Number(statsLite.stdev(data).toFixed(2)),
          show: false
        },
        {
          label: ANALYZE_COUNT_LABEL,
          value: sum(counts),
          show: true
        }
      ]
    }
  },
}

export const availableMolecules = keys(formMolecules);

export const formMoleculesWithDerivations = {
  ...formMolecules,
  ...derivedMolecules
}
