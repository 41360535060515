import React, { Fragment, useEffect, useState, forwardRef, useContext } from "react";
import { useParams, useHistory, useLocation, Link } from "react-router-dom";
import { Row, Col, Button } from 'reactstrap';
import { isEmpty, omit, find } from 'lodash';
import Loading from "../../loading";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import Animation from 'rsuite/Animation';
import { format } from 'date-fns';

import Joi from 'joi';

import {
  fetchDefinitions,
  fetchDefinition,
  fetchLogsByDefinition,
  submitLog,
  deleteDefinition,
  deleteLog
} from '../../../services/api';
import { processOrganism, processOrganismWithLogData, buildPayloadForLog } from '../../../services/logger';
import { ONE_SECOND, FIRST_ITEM } from '../../../utils/constants';
import { findLabelValue } from '../../../utils/helpers';

import Molecule from '../../molecule';
import DefinitionsSelector from '../../definitions-selector';
import BreadCrumb from '../../breadcrumb';
import TagsSelector from '../../tags-selector';

import { UserContext } from '../../../contexts/user'

import './index.css';

const formFieldSchema = Joi.object({
  id: Joi.string(),
  value: Joi.any().when('required', [
    {
      is: true,
      then: Joi.any().not(null, '').error(errors => {
        errors.forEach(err => {
          switch(err.code) {
            case "any.invalid":
              err.message = `Missing required fields.`;
              break;
            default:
              break;
          }
        })

        return errors;
      })
    },
    { is: false, then: Joi.optional() }
  ]),
  required: Joi.boolean().required()
});

const LogTable = ({
  logs,
  definition,
  isLoading,
  onClickEdit,
  onClickDelete
}) => {
  const [toDelete, setToDelete] = useState({});

  const handleClickInitDelete = id => {
    setToDelete({ ...toDelete, [id]: true });
  }

  const handleClickCancelDelete = id => {
    setToDelete(omit(toDelete, id));
  }

  if (isLoading) {
    return (
      <Loading />
    )
  }

  if (definition && logs && logs.length > 0) {
    return (
      <Row>
        <Col xs={12}>
          <div class="log-table table-responsive">
            <table class="table mb-0">
              <thead>
                <tr>
                  {definition.molecules.map(({ atoms, label }) => {
                    const header = findLabelValue({ collection: atoms, defaultValue: label });

                    return (
                      <th>{header}</th>
                    )
                  })}
                  <th>Tags</th>
                  <th>Logged</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {logs.map(({ createdAt, date, data, tags, _id: logId }) => {
                  const formattedDate = format(new Date(date), "MMM do, y");
                  const formattedCreatedAt = format(new Date(createdAt), "cccc MMM do, y, h:mm aa");

                  return (
                    <tr>
                      {definition.molecules.map(({ id }) => {
                        const isDate = id.split('|')[1] === 'default-date';

                        return (
                          <td>{isDate ? formattedDate : data[id]}</td>
                        )
                      })}
                      <td>{tags.join(',')}</td>
                      <td>{formattedCreatedAt}</td>
                      <td className="log-table-action">
                        <i onClick={() => onClickEdit(logId)} className="bi bi-pencil"></i>{' '}
                        {toDelete[logId] ? (
                          <Fragment>
                            <i onClick={() => onClickDelete(logId)} className="bi bi-check-circle"></i>{' '}
                            <i onClick={() => handleClickCancelDelete(logId)} className="bi bi-x-circle"></i>
                          </Fragment>
                        ) : (
                          <i onClick={() => handleClickInitDelete(logId)} className="bi bi-trash"></i>
                          )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    )
  }

  return ('');
}

const QuestionnaireFormFields = forwardRef(({ style, ...props }, ref) => {
  const {
    selectedDefinition,
    selectedTags,
    handleChangeTags,
    availableTags,
    isUserTagsLoading,
    formFields,
    handleChangeFormField,
    formFieldErrorMapping
  } = props;

  return (
    <div
      ref={ref}
      style={{
        ...style,
        overflow: 'hidden',
        transition: '700ms ease-in-out height'
      }}
    >
      <Row>
        <Col>
          <h3 className="mb-2 text-center questionnaire-title">{selectedDefinition.name}</h3>
        </Col>
      </Row>
      <Row>
        <Col xs={'12'}>
            {formFields && formFields.map(formField => {
              return (
                <Row key={formField.id} form>
                  <Col>
                    <Molecule
                      formField={formField}
                      onChangeFormField={handleChangeFormField}
                      isError={formFieldErrorMapping[formField.id]}
                    />
                  </Col>
                </Row>
              );
            })}
        </Col>
      </Row>
      <Row>
        <Col xs={'12'}>
          <TagsSelector
            isLog
            className="formfield"
            selectedTags={selectedTags}
            availableTags={availableTags}
            isLoading={isUserTagsLoading}
            onChangeTags={handleChangeTags}
          />
        </Col>
      </Row>
    </div>
  )
});

const Questionnaire = ({
  selectedDefinition,
  selectedTags,
  availableTags,
  isEdit,
  isUserTagsLoading,
  handleChangeTags,
  handleChangeFormField,
  handleClickSubmit,
  handleClickSubmitAgain,
  handleClickSelectDifferentQuestionnaire,
  formFields,
  hasErrors,
  formFieldErrorMapping,
  formFieldErrors,
  isSubmitting,
  isLoading,
  isHiding,
  isSuccess
}) => {
  if (isLoading) {
    return (
      <Loading />
    );
  }

  const submitMessage = isEdit ? 'Save' : 'Submit';
  const submittingMessage = isEdit ? 'Saving...': 'Submitting...';

  if (selectedDefinition) {
    return (
      <div className="questionnaire" >
        <Animation.Collapse in={!isHiding}>
          {(props, ref) => <QuestionnaireFormFields
            ref={ref}
            selectedDefinition={selectedDefinition}
            selectedTags={selectedTags}
            availableTags={availableTags}
            handleChangeTags={handleChangeTags}
            isUserTagsLoading={isUserTagsLoading}
            formFields={formFields}
            handleChangeFormField={handleChangeFormField}
            formFieldErrorMapping={formFieldErrorMapping}
          />}
        </Animation.Collapse>
        <Row className="mb-2">
          {isSuccess ? (
            <Col>
              <Button
                onClick={handleClickSubmitAgain}
                color="info"
                className="mr-2 mb-2"
                outline
              >
                Add A New Entry
              </Button>
              <Button
                onClick={handleClickSelectDifferentQuestionnaire}
                color="primary"
                outline
              >
                Select A Different Questionnaire
              </Button>
            </Col>
          ) : (
            <Col>
              <Button
                onClick={handleClickSubmit}
                color={hasErrors ? 'danger' : 'success'}
                outline
                className="mr-2"
                disabled={hasErrors || isSubmitting}
              >
                {hasErrors ? formFieldErrors[FIRST_ITEM] : (isSubmitting ? submittingMessage : submitMessage)}
              </Button>
              {isEdit && (
                <Link
                  to={`/log/${selectedDefinition._id}`}
                >
                  <Button
                    color={'info'}
                    outline
                    disabled={hasErrors || isSubmitting}
                  >
                    Add New Entry
                  </Button>
                </Link>
              )}
            </Col>
          )}
        </Row>
      </div>
    )
  }

  return ('')
}

const Log = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { id: definitionId, entry: logId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { userState } = useContext(UserContext);

  const { tags: availableTags, isLoading: isUserTagsLoading } = userState;

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [definitions, setDefinitions] = useState([]);
  const [logs, setLogs] = useState([]);
  const [selectedDefinition, setSelectedDefinition] = useState(null);

  const [selectedTags, setSelectedTags] = useState([]);

  const [formFields, setFormFields] = useState(null);
  const [payload, setPayload] = useState(null);

  const [formFieldErrors, setFormFieldErrors] = useState([]);
  const [formFieldErrorMapping, setFormFieldErrorMapping] = useState({});

  const [isHiding, setIsHiding] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [isDefinitionNotFound, setIsDefinitionNotFound] = useState(false);

  useEffect(() => {
    setIsDefinitionNotFound(false);
  }, [location])

  useEffect(() => {
    const editStatus = definitionId && logId && (find(logs, { _id: logId }) || false);
    setIsEdit(editStatus);
  }, [definitionId, logId, logs])

  useEffect(() => {
    const entry = logId && find(logs, { _id: logId });

    if (selectedDefinition && entry) {
      setFormFields(processOrganismWithLogData({ definition: selectedDefinition, logData: entry }));
    } else if (selectedDefinition) {
      setFormFields(processOrganism(selectedDefinition));
    }
  }, [logId])

  const getDefinition = async id => {
    const token = await getAccessTokenSilently();
    const { data: { definition } } = await fetchDefinition({ token, id });
    const { data: { logs } } = await fetchLogsByDefinition({ token, id });

    const openLog = logId && find(logs, { _id: logId });

    if (isEmpty(definition)) {
      setIsDefinitionNotFound(true);
    } else {
      setTimeout(() => {
        setSelectedDefinition(definition);
        setSelectedTags(definition.tags);
        setLogs(logs);
        if (openLog) {
          setFormFields(processOrganismWithLogData({ definition, logData: openLog }));
        } else {
          setFormFields(processOrganism(definition));
        }
        setIsLoading(false);
      }, ONE_SECOND);
    }
  }

  const getDefinitions = async () => {
    const token = await getAccessTokenSilently();
    const { data: { definitions } } = await fetchDefinitions(token);

    setTimeout(() => {
      setDefinitions(definitions);
      setIsLoading(false);
    }, ONE_SECOND);
  }

  useEffect(() => {
    if (isEmpty(definitionId)) {
      setIsLoading(true);
      setSelectedDefinition(null);
      getDefinitions();
    } else {
      setIsLoading(true);
      getDefinition(definitionId);
    }
  }, [definitionId])

  useEffect(() => {
    if (selectedDefinition && formFields) {
      const updatedPayload = buildPayloadForLog({
        definition: selectedDefinition,
        formFields,
        selectedTags,
        editId: logId
      });

      setPayload(updatedPayload);
    }
  }, [selectedDefinition, formFields, selectedTags, logId]);

  const handleChangeFormField = ({ formFieldId, value }) => {
    setFormFieldErrors([]);
    setFormFieldErrorMapping({});

    const updatedFormFields = formFields.map(formField => {
      if (formField.id === formFieldId) {
        return {
          ...formField,
          value
        }
      }

      return formField;
    })

    setFormFields(updatedFormFields);
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  const handleClickSubmit = async () => {
    const updatedFormFieldErrors = [];
    const updatedFormFieldErrorMapping = {};

    formFields.forEach(updatedFormField => {
      const { error } = formFieldSchema.validate({
        id: updatedFormField.id,
        value: updatedFormField.value,
        required: updatedFormField.required
      });

      if (error) {
        updatedFormFieldErrorMapping[updatedFormField.id] = true
        updatedFormFieldErrors.push(error.message);
      }
    })

    if (updatedFormFieldErrors.length > 0) {
      setFormFieldErrors(updatedFormFieldErrors);
      setFormFieldErrorMapping(updatedFormFieldErrorMapping);
    } else {
      setIsSubmitting(true);

      try {
        const token = await getAccessTokenSilently();
        const { data } = await submitLog({ token, log: payload });
        const { data: { logs } } = await fetchLogsByDefinition({ token, id: selectedDefinition._id });

        if (data?.success) {
          setTimeout(
            () => {
              setIsSubmitting(false);
              setIsHiding(true);
              setIsSuccess(true);
              setLogs(logs);
              setFormFields(processOrganism(selectedDefinition));
              scrollToTop();
            },
            ONE_SECOND
          );
        }
      } catch (error) {
        setIsSubmitting(false);
        setFormFieldErrors([error.message])
      }
    }
  }

  const handleClickSubmitAgain = () => {
    setIsSuccess(false);
    setIsHiding(false);
    history.push(`/log/${selectedDefinition._id}`);
  }

  const handleClickSelectDifferentQuestionnaire = () => {
    setIsSuccess(false);
    setIsHiding(false);
    history.push('/log');
  }

  const handleConfirmDeleteDefinition = async definitionId => {
    setIsLoading(true);

    const token = await getAccessTokenSilently();

    try {
      const { data: { definition } } = await deleteDefinition({ token, id: definitionId });
      getDefinitions();
    } catch (error) {
      setFormFieldErrors([error.message])
    }
  }

  const handleChangeTags = tags => {
    setSelectedTags(tags);
  }

  const handleClickEditLog = logId => {
    setIsSuccess(false);
    setIsHiding(false);
    history.push(`/log/${selectedDefinition._id}/${logId}`);
  }

  const handleClickDeleteLog = async id => {
    setIsSubmitting(true);

    try {
      const token = await getAccessTokenSilently();
      const { data } = await deleteLog({ token, id });
      const { data: { logs } } = await fetchLogsByDefinition({ token, id: selectedDefinition._id });

      if (data?.success) {
        setTimeout(
          () => {
            setIsSubmitting(false);
            setLogs(logs);
            setFormFields(processOrganism(selectedDefinition));
          },
          ONE_SECOND
        );
      }
    } catch (error) {
      setIsSubmitting(false);
      setFormFieldErrors([error.message])
    }
  }

  const hasErrors = !isEmpty(formFieldErrors);

  const paths = [{ name: 'Log', link: '/log'}]

  if (selectedDefinition) {
    paths.push({
      name: selectedDefinition.name,
      link: `/log/${selectedDefinition._id}`
    })
  }

  const showQuestionnaire = !isEmpty(definitionId);

  return (
    <Fragment>
      <BreadCrumb paths={paths}/>
      <section className="pt-4 pb-4">
        <div className="container-fluid">
          <Row>
            <Col>
              {isEdit ? !isHiding && (
                <Fragment>
                  <h4>Editing Entry: <span className="text-thin">{logId}</span></h4>
                </Fragment>
              ) : (
                <Fragment>
                  <h4>Complete a Questionnaire</h4>
                  <p className="text-muted text-small mb-4">Fill out one of your custom made questionnaires to compile your activity data.</p>
                </Fragment>
              )}

              {isDefinitionNotFound ? (
                <h4 className="text-muted">Sorry, we could not find that questionnaire.</h4>
              ) : showQuestionnaire ? (
                <Fragment>
                  <Questionnaire
                    selectedDefinition={selectedDefinition}
                    selectedTags={selectedTags}
                    availableTags={availableTags}
                    isEdit={isEdit}
                    isUserTagsLoading={isUserTagsLoading}
                    handleChangeTags={handleChangeTags}
                    handleChangeFormField={handleChangeFormField}
                    handleClickSubmit={handleClickSubmit}
                    handleClickSubmitAgain={handleClickSubmitAgain}
                    handleClickSelectDifferentQuestionnaire={handleClickSelectDifferentQuestionnaire}
                    formFields={formFields}
                    hasErrors={hasErrors}
                    formFieldErrorMapping={formFieldErrorMapping}
                    formFieldErrors={formFieldErrors}
                    isSubmitting={isSubmitting}
                    isLoading={isLoading}
                    isHiding={isHiding}
                    isSuccess={isSuccess}
                  />
                  <LogTable
                    logs={logs}
                    definition={selectedDefinition}
                    isLoading={isLoading || isSubmitting}
                    onClickEdit={handleClickEditLog}
                    onClickDelete={handleClickDeleteLog}
                  />
                </Fragment>
              ) : (
                <DefinitionsSelector
                  definitions={definitions}
                  isLoading={isLoading}
                  onConfirmDeleteDefinition={handleConfirmDeleteDefinition}
                />
              )}
            </Col>
          </Row>
        </div>
      </section>
    </Fragment>
  );
};

export default withAuthenticationRequired(Log, {
  onRedirecting: () => <Loading />,
});