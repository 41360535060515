import React, { useReducer, createContext } from 'react';

const UIContext = createContext();

const initialState = {
  isSidebarOpen: true
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_SIDEBAR':
      return {
        ...state,
        isSidebarOpen: !state.isSidebarOpen
      }
    default:
      return state;
  }
};

function UIContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { uiState: state, dispatchUI: dispatch };

  return (
    <UIContext.Provider value={value}>
      {children}
    </UIContext.Provider>
  );
}

const UIContextConsumer = UIContext.Consumer;

export { UIContext, UIContextProvider, UIContextConsumer };
