import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";

import { UIContext } from '../../contexts/ui'

const SidebarNav = () => {
  const {
    user,
    isAuthenticated,
    logout
  } = useAuth0();

  const logoutWithRedirect = () => logout({ returnTo: window.location.origin });

  const { uiState } = useContext(UIContext);

  const { isSidebarOpen } = uiState;

  const location = useLocation();

  if (!isAuthenticated) {
    return ('');
  }

  return (
    <nav id="sidebar" className={`${isSidebarOpen ? '' : 'shrinked'}`}>
      <div className="sidebar-header d-flex align-items-center p-4"><img className="avatar shadow-0 img-fluid rounded-circle" src={user.picture} alt="..."/>
        <div className="ms-3 title">
          <h1 className="h5 mb-1">{user.nickname}</h1>
        </div>
      </div>
      <span className="text-uppercase text-gray-600 text-xs mx-3 px-2 heading mb-2">Main</span>
      <ul className="list-unstyled">
        <li className={`sidebar-item ${location.pathname === '/' ? 'active' : ''}`}>
          <NavLink to="/" exact className="sidebar-link">
            <i className="svg-icon svg-icon-sm svg-icon-heavy bi bi-house"></i>
            <span>Home</span>
          </NavLink>
        </li>
        <li className={`sidebar-item ${location.pathname === '/dashboard' ? 'active' : ''}`}>
          <NavLink to="/dashboard" exact className="sidebar-link">
            <i className="svg-icon svg-icon-sm svg-icon-heavy bi bi-columns-gap"></i>
            <span>Dashboard</span>
          </NavLink>
        </li>
        <li className={`sidebar-item ${location.pathname === '/define' ? 'active' : ''}`}>
          <NavLink to="/define" exact className="sidebar-link">
            <i className="svg-icon svg-icon-sm svg-icon-heavy bi bi-diagram-3"></i>
            <span>Define</span>
          </NavLink>
        </li>
        <li className={`sidebar-item ${location.pathname === '/log' ? 'active' : ''}`}>
          <NavLink to="/log" exact className="sidebar-link">
            <i className="svg-icon svg-icon-sm svg-icon-heavy bi bi-journal-check"></i>
            <span>Log</span>
          </NavLink>
        </li>
        <li className={`sidebar-item ${location.pathname === '/analyze' ? 'active' : ''}`}>
          <NavLink to="/analyze" className="sidebar-link">
            <i className="svg-icon svg-icon-sm svg-icon-heavy bi bi-graph-up"></i>
            <span>Analyze</span>
          </NavLink>
        </li>
        <li className="sidebar-item">
          <NavLink onClick={() => logoutWithRedirect()} to="" className="sidebar-link">
            <i className="svg-icon svg-icon-sm svg-icon-heavy bi bi-unlock"></i>
            <span>Logout</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default SidebarNav;
