import React from "react";

const Footer = () => (
  <footer className="position-absolute bottom-0 bg-dash-dark-2 text-white text-center py-3 w-100 text-xs" id="footer">
    <div className="container-fluid text-center">
      <p className="mb-0 text-dash-gray">2023 &copy; GRYT Progress | Made with ❤ by <a href="https://nafeu.com">nafeu</a>.</p>
    </div>
  </footer>
);

export default Footer;
