export const ANALYZE_SUM_LABEL = 'Total';
export const ANALYZE_MEAN_LABEL = 'Daily Avg';
export const ANALYZE_VARIANCE_LABEL = 'Variance';
export const ANALYZE_STDV_LABEL = 'Stdv';
export const ANALYZE_COUNT_LABEL = 'Entries';

export const MEASUREMENT_SENTIMENT_LABEL = 'by sentiment (from -5 to 5)';
export const MEASUREMENT_LENGTH_OF_TEXT_LABEL = 'by length of text';
export const MEASUREMENT_EXISTENCE_LABEL = 'by existence (0 or 1)';
export const MEASUREMENT_LAST_ENTRY_LABEL = 'by latest submission of the day';
export const MEASUREMENT_COUNTABLE_LABEL = 'as sum of all submissions of the day';
