import React, { useEffect, useContext } from "react";
import { Router } from "react-router-dom";
import { CustomProvider } from 'rsuite';

import Loading from "./components/loading";
import SidebarNav from "./components/sidebar-nav";
import Header from './components/header';
import PageContent from "./components/page-content";

import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

import { syncUser } from "./services/api";

import { UIContextProvider } from './contexts/ui';
import { UserContext } from './contexts/user';

const App = () => {
  const { getAccessTokenSilently, user, isLoading, error } = useAuth0();
  const { userState, dispatchUser } = useContext(UserContext);

  useEffect(() => {
    const syncActiveUser = async location => {
      if (user) {
        try {
          const token = await getAccessTokenSilently();
          dispatchUser({
            type: 'SET_IS_LOADING',
            payload: {
              isLoading: true
            }
          })
          const { data: { user: userData } } = await syncUser(token, user);
          dispatchUser({
            type: 'SET_USER',
            payload: {
              user: userData
            }
          })
        } catch (error) {
          console.error({ error })
        }
      }
    }

    syncActiveUser();
    history.listen(syncActiveUser);
  }, [user, getAccessTokenSilently])

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <div className="centered-loading">
      <Loading />
    </div>;
  }

  return (
    <CustomProvider theme="dark">
      <UIContextProvider>
        <Router history={history}>
          <Header />
          <div className="d-flex align-items-stretch">
            <SidebarNav />
            <PageContent />
          </div>
        </Router>
      </UIContextProvider>
    </CustomProvider>
  );
};

export default App;
