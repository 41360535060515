import React, { Fragment, useContext, useState, useEffect } from "react";
import { FormGroup, Label, Row, Col } from "reactstrap";
import TagPicker from 'rsuite/TagPicker';

import Loading from '../loading';

import './index.css';

const TagsSelector = ({ selectedTags, availableTags, isLoading, onChangeTags, className, isLog }) => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (availableTags) {
      const tagsCollection = availableTags.map(
        tag => ({
          label: tag,
          value: tag
        })
      )
      setTags(tagsCollection)
    }
  }, [availableTags])

  if (isLoading || !availableTags) return (
    <Loading className="m-4" />
  );

  if (isLog) {
    return (
      <FormGroup className="form-molecule">
        <Label className="mr-2">Select Tags</Label>
        <TagPicker
          creatable
          menuStyle={{ width: 100 }}
          className="tags-selector-picker mb-1"
          placeholder="Select Tags"
          data={tags}
          onChange={selectedTags => onChangeTags(selectedTags)}
          value={selectedTags}
        />
      </FormGroup>
    );
  }

  return (
    <div className={className}>
      <p className="text-center p-2 help-header"><i className="bi bi-tag mr-2"></i>Default Tags</p>
      <div className="tags-selector">
        <Row>
          <Col xs={12}>
            <TagPicker
              creatable
              block
              menuStyle={{ width: 100 }}
              className="tags-selector-picker mb-1"
              placeholder="Select Tags"
              data={tags}
              onChange={selectedTags => onChangeTags(selectedTags)}
              value={selectedTags}
            />
            <div className="tags-selector-info">
              Use tags to help categorize your activity for more interesting and complex analysis.
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TagsSelector;
