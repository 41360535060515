import React, { useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { UIContext } from '../../contexts/ui'

const Header = () => {
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();

  const logoutWithRedirect = () => logout({ returnTo: window.location.origin });

  const { uiState, dispatchUI } = useContext(UIContext);

  const { isSidebarOpen } = uiState

  const handleClickSidebarToggle = () => {
    dispatchUI({
      type: 'TOGGLE_SIDEBAR',
      payload: {}
    })
  }

  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg py-3 bg-dash-dark-2 border-bottom border-dash-dark-1 z-index-10">
        <div className="container-fluid d-flex align-items-center justify-content-between py-1">
          <div className="navbar-header d-flex align-items-center"><a className={`navbar-brand text-reset ${isSidebarOpen ? '' : 'active'}`} href="/">
            <div className="brand-text brand-big"><strong className="text-primary">gryt-</strong><strong>progress</strong></div>
            <div className="brand-text brand-sm"><strong className="text-primary">gryt-</strong><strong>progress</strong></div></a>
            {isAuthenticated && (
              <button onClick={handleClickSidebarToggle} className={`sidebar-toggle ${isSidebarOpen ? '' : 'active'}`}>
                <i className="bi bi-arrow-left"></i>
              </button>
            )}
          </div>
          <ul className="list-inline mb-0">
            <li className="list-inline-item logout px-lg-2">
              {!isAuthenticated && (
                <div onClick={() => loginWithRedirect({})} className="nav-link cursor-pointer text-sm text-reset px-1 px-lg-0">
                  <span className="d-none d-sm-inline-block">Login <i className="bi bi-person-circle"></i></span>
                </div>
              )}
              {isAuthenticated && (
                <div onClick={() => logoutWithRedirect()} className="nav-link cursor-pointer text-sm text-reset px-1 px-lg-0">
                  <span className="d-none d-sm-inline-block">Logout <i className="bi bi-unlock"></i></span>
                </div>
              )}
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
