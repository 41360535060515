import React, { Fragment } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { filter } from "lodash";

import { processDataForActivitySummary } from './helpers';

import './index.css';

const ThirtyDayLogs = ({ data }) => {
  return (
    <Fragment>
      <div className="thirty-day-logs-title">Last 30 Days</div>
      <div className="thirty-day-logs">
        {data.map(({ count }) => {

          if (count > 0) {
            let opacity = 0.1;

            if (count >= 2) {
              opacity = 0.25;
            }

            if (count >= 4) {
              opacity = 0.75;
            }

            if (count >= 5) {
              opacity = 1;
            }

            return (
              <div className="thirty-day-logs-active" style={{ opacity }}>
                <i className="bi bi-circle-fill"></i>
              </div>
            )
          }

          return (
            <div className="thirty-day-logs-inactive" style={{ opacity: 0.1 }}>
              <i className="bi bi-circle"></i>
            </div>
          )
        })}
      </div>
    </Fragment>
  )
}

const ActivitySummary = ({ data, isLoading }) => {
  if (isLoading) {
    return (
      <div>Loading...</div>
    );
  }

  const { summaries, thirtyDayLogs } = processDataForActivitySummary(data);

  return (
    <Row>
      <Col xs={12}>
        <div className="activity-summaries">
          <Row>
            {summaries.map(({ group, name, summary, comparison }) => {
              return (
                <Col xs={12} md={group === 'all' ? 6 : 3}>
                  <div className={`activity-summary comparison-${comparison}`}>
                    <div className="activity-summary-name">
                      {name}
                    </div>
                    <div className="activity-summary-content">
                      {summary.map(({ label, value }) => {
                        return (
                          <div className="activity-summary-item">
                            <div className="activity-summary-label">{label}</div>
                            <div className="activity-summary-value">{value}</div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
            <Col xs={12}>
              <ThirtyDayLogs
                data={thirtyDayLogs}
              />
            </Col>
          <Row>

          </Row>
        </div>
      </Col>
    </Row>
  );
}

export default ActivitySummary;
