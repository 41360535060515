import React from "react";
import { each, keys } from 'lodash';
import { parseId } from '../../utils/helpers';
import { FormGroup, Label, Row, Col, ButtonGroup, Button, Input, Badge } from 'reactstrap';
import DatePicker from 'rsuite/DatePicker';
import Slider from 'rsuite/Slider';

import './index.css';

const getInputProps = ({ formField, moleculeId }) => {
  const inputProps = {}

  each(keys(formField), key => {
    if (key !== 'label') {
      inputProps[key] = formField[key];
    }
  });

  return inputProps;
}

const Molecule = ({ formField, onChangeFormField, isError }) => {
  const moleculeId = parseId(formField.id);

  if (moleculeId === 'yesnoMolecule') {
    return (
      <FormGroup className={`form-molecule ${isError ? 'form-error' : ''}`}>
        <Label className="mb-2" for={formField.id}>{formField.label}</Label>
        <Row>
          <Col>
            <ButtonGroup>
              <Button
                color={formField.value === 1 ? "primary" : "secondary"}
                onClick={() => onChangeFormField({ formFieldId: formField.id, value: 1 })}
                active={formField.value === 1}
                outline
              >
                Yes
              </Button>
              <Button
                color={formField.value === 0 ? "primary" : "secondary"}
                onClick={() => onChangeFormField({ formFieldId: formField.id, value: 0 })}
                active={formField.value === 0}
                outline
              >
                No
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </FormGroup>
    );
  }

  if (moleculeId === 'rangeMolecule') {
    return (
      <FormGroup className={`form-molecule ${isError ? 'form-error' : ''}`}>
        <Label className="mb-2" for={formField.id}>{formField.label} <Badge color="primary">{formField.value}</Badge></Label>
        <Slider
          id={formField.id}
          onChange={value => onChangeFormField({ formFieldId: formField.id, value })}
          step={1}
          graduated
          progress
          {...getInputProps({ formField, moleculeId })}
        />
      </FormGroup>
    );
  }

  if (moleculeId === 'imageMolecule') {
    return (
      <FormGroup className={`form-molecule ${isError ? 'form-error' : ''}`}>
        <Label for={formField.id}>{formField.label} <Badge color="primary">{formField.value}</Badge></Label>
        <Input
          id={formField.id}
          onChange={event => console.log({ files: event.target.files })}
          {...getInputProps({ formField, moleculeId })}
          capture
        />
      </FormGroup>
    );
  }

  if (moleculeId === 'dateMolecule') {
    return (
      <FormGroup className={`form-molecule ${isError ? 'form-error' : ''}`}>
        <Label className="mr-2" for={formField.id}>{formField.label}</Label>
        <DatePicker
          oneTap
          id={formField.id}
          onChange={(date, event) => onChangeFormField({ formFieldId: formField.id, value: date })}
          {...getInputProps({ formField, moleculeId })}
        />
      </FormGroup>
    )
  }

  return (
    <FormGroup className={`form-molecule ${isError ? 'form-error' : ''}`}>
      <Label className="mb-2" for={formField.id}>{formField.label}</Label>
      <Input
        id={formField.id}
        onChange={event => onChangeFormField({ formFieldId: formField.id, value: event.target.value })}
        {...getInputProps({ formField, moleculeId })}
      />
    </FormGroup>
  );
}

export default Molecule;
