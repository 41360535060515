import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";

const getLinks = paths => paths.map(({ name, link }, index) => {
  const isLastItem = index === paths.length - 1;

  return (
    <li
      key={`${name}_${link}`}
      className={`breadcrumb-item ${index === paths.length - 1 ? 'active' : ''}`}
    >
      {isLastItem ? (
        <span>{name}</span>
      ) : (
        <NavLink to={link}>
          {name}
        </NavLink>
      )}
    </li>
  )
})

const homePath = { name: 'Home', link: '/' }

const BreadCrumb = ({ paths = [homePath] }) => (
  <Fragment>
    <div className="container-fluid py-2">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb mb-0 py-3 px-0">
          {getLinks([homePath, ...paths])}
        </ol>
      </nav>
    </div>
  </Fragment>
);

export default BreadCrumb;
