import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";

import Footer from "../footer";

import Home from "../pages/home";
import Dashboard from "../pages/dashboard";
import Profile from "../pages/profile";
import Define from "../pages/define";
import Log from "../pages/log";
import Analyze from "../pages/analyze";

import { UIContext } from '../../contexts/ui';

const PageContent = () => {
  const { uiState } = useContext(UIContext);
  const { isSidebarOpen } = uiState

  return (
    <div className={`page-content ${isSidebarOpen ? '' : 'active'}`}>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/dashboard" exact component={Dashboard} />
        <Route path="/profile" component={Profile} />
        <Route path="/define" component={Define} />
        <Route path="/log/:id/:entry" component={Log} />
        <Route path="/log/:id?" component={Log} />
        <Route path="/analyze" component={Analyze} />
      </Switch>
      <Footer/>
    </div>
  );
};

export default PageContent;
