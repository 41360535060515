import React, { Fragment } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import './index.css';

const Home = () => {
  const {
    isAuthenticated,
    loginWithRedirect
  } = useAuth0();

  return (
    <section className="pt-4 pb-4">
      <div className="container-fluid">
        <div className="row gy-4">
          <div className="text-center hero my-5">
            <h1 className="mb-4">GRYT Progress</h1>

            <p className="lead">
              Self-guided behavioural analysis, goal tracking and habit tracking tools.
            </p>

            {!isAuthenticated && (
              <div onClick={() => loginWithRedirect({})} className="home-button">
                Login <i className="bi bi-person-circle"></i>
              </div>
            )}

            {isAuthenticated && (
              <Fragment>
                <Link to="/define">
                  Define Your Progress Metrics <i className="bi bi-diagram-3"></i>
                </Link>
                <br/>
                <Link to="/log">
                  Complete a Questionnaire <i className="bi bi-journal-check"></i>
                </Link>
                <br/>
                <Link to="/analyze">
                  Analyze Your Activity <i className="bi bi-graph-up"></i>
                </Link>
              </Fragment>
            )}
          </div>
        </div>
        <div className="row gy-4">
          <hr/>
          <h3>The Journey of Self-Reliance</h3>
          <p>GRYT Progress is an optimistic and objective approach to self-improvement, self-understanding and productivity. It operates under the belief that deep down, everyone wants to do better for themselves and the first step to being a better version of yourself is acquiring an objective understanding of "why" you do things.</p>
        </div>
      </div>
    </section>
  )
};

export default Home;
