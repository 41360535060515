import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Row, Col, Card, CardBody } from 'reactstrap';
import { filter, isEmpty } from 'lodash';

import './index.css';

import { fetchStats } from '../../../../services/api';
import { ONE_SECOND } from '../../../../utils/constants';
import { QueryContext } from '../../../../contexts/query';
import Loading from '../../../loading';

import DataSheet from '../../../data-sheet';
import Multitype from '../../../chart/multitype';
import Statistics from '../../../statistics';

const Stats = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { query } = useContext(QueryContext);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    setIsLoading(true);

    const getStats = async () => {
      const token = await getAccessTokenSilently();
      const { data: { stats, definitions } } = await fetchStats(token, query);

      setTimeout(() => {
        setData({ stats, definitions });
        setIsLoading(false);
      }, ONE_SECOND);
    }

    if (query?.range?.start && query?.range?.end) {
      getStats();
    }
  }, [query]);

  if (isLoading) return (
    <Loading />
  );

  const hasNoData = data === null || filter(data.stats, item => !isEmpty(item.data)).length === 0;

  if (hasNoData) {
    return (
      <p>
        <i className="bi bi-exclamation-triangle"></i> No data to display, please update your selection.
      </p>
    )
  }

  return (
    <Fragment>
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <DataSheet
                data={data}
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
        </Col>
        <Col xs={12}>
          <Card className="mb-4" xs={12}>
            <CardBody className="multitype-chart">
              <Multitype
                data={data}
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
          <Statistics
            data={data}
            isLoading={isLoading}
          />
        </Col>
      </Row>
    </Fragment>
  );
}

export default Stats;
