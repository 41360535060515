import React, { useState } from "react";
import { Col } from 'reactstrap';
import { Row } from 'reactstrap';
import Loading from "../../loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { QueryContextProvider } from '../../../contexts/query';

import MoleculesSelector from '../../molecules-selector';
import BreadCrumb from '../../breadcrumb';
import Stats from './stats';

const Analyze = () => {
  const [query, setQuery] = useState({});

  const handleQuery = async query => {
    setQuery(query);
  }

  return (
    <QueryContextProvider>
      <BreadCrumb paths={
        [
          {
            name: 'Analyze',
            link: '/analyze'
          }
        ]
      }/>
      <section className="pt-4 pb-4">
        <div className="container-fluid">
          <Row>
            <Col>
              <h4>Analyze Your Activity</h4>
              <p className="text-muted text-small mb-4">Select a date range and the metrics you defined to explore your behaviour.</p>
              <Row className="mb-4">
                <Col>
                  <MoleculesSelector onQuery={handleQuery} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Stats query={query} />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </section>
    </QueryContextProvider>
  );
};

export default withAuthenticationRequired(Analyze, {
  onRedirecting: () => <div className="centered-loading"><Loading /></div>,
});
