import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Row, Col, Card, CardBody } from 'reactstrap';
import { filter, isEmpty } from 'lodash';
import { useEffectOnce } from 'react-use';

import { fetchActivity, fetchStats } from '../../../services/api';
import { ONE_SECOND } from '../../../utils/constants';
import Loading from '../../loading';

import Multitype from '../../chart/multitype';
import Statistics from '../../statistics';
import ActivitySummary from '../../activity-summary';

import BreadCrumb from '../../breadcrumb';

import './index.css';

const Dashboard = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    logs: null,
    activity: null
  });

  useEffectOnce(() => {
    setIsLoading(true);

    const getStats = async () => {
      const token = await getAccessTokenSilently();
      const query = { fetchAll: true }
      const { data: { stats, definitions } } = await fetchStats(token, query);
      const { data: { activity, thirtyDayLogs } } = await fetchActivity(token);

      setTimeout(() => {
        setData({
          ...data,
          logs: { stats, definitions },
          activity,
          thirtyDayLogs
        });
        setIsLoading(false);
      }, ONE_SECOND);
    }

    getStats();
  }, []);

  if (isLoading) return (
    <Loading className="m-4" />
  );

  const hasNoData = data.logs === null || filter(data.logs.stats, item => !isEmpty(item.data)).length === 0;

  if (hasNoData) {
    return (
      <p className="m-4">
        <i className="bi bi-exclamation-triangle"></i> No dashboard data available yet, as you log more data a dashboard will be generated.
      </p>
    )
  }

  return (
    <div className="page-dashboard">
      <BreadCrumb paths={
        [
          {
            name: 'Dashboard',
            link: '/dashboard'
          }
        ]
      }/>
      <section className="pt-4 pb-4">
        <div className="container-fluid">
          <div className="row gy-4">
            <Row>
              <Col xs={12}>
                <ActivitySummary
                  data={data}
                  isLoading={isLoading}
                />
              </Col>
              <Col xs={12}>
                <Card className="mb-4" xs={12}>
                  <CardBody>
                    <Multitype
                      data={data.logs}
                      isLoading={isLoading}
                    />
                  </CardBody>
                </Card>
                <Statistics
                  data={data.logs}
                  isLoading={isLoading}
                />
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
