import axios from 'axios';

const buildAuthHeader = token => ({
  headers: {
    'Authorization': `Bearer ${token}`
  }
})

export const syncUser = (token, user) => axios.post(
  '/api/v1/user/sync',
  { user },
  buildAuthHeader(token)
);

export const saveDefinition = (token, formDefinition) => axios.post(
  '/api/v1/definition',
  { formDefinition },
  buildAuthHeader(token)
);

export const fetchDefinitions = token => axios.get(
  '/api/v1/definition',
  buildAuthHeader(token)
);

export const fetchDefinition = ({ token, id }) => axios.get(
  `/api/v1/definition/${id}`,
  buildAuthHeader(token)
);

export const deleteDefinition = ({ token, id }) => axios.delete(
  `/api/v1/definition/${id}`,
  buildAuthHeader(token)
);

export const fetchLogsByDefinition = ({ token, id }) => axios.get(
  `/api/v1/log/${id}`,
  buildAuthHeader(token)
);

export const submitLog = ({ token, log }) => axios.post(
  '/api/v1/log',
  { log },
  buildAuthHeader(token)
);

export const deleteLog = ({ token, id }) => axios.delete(
  `/api/v1/log/${id}`,
  buildAuthHeader(token)
);

export const fetchStats = (token, query) => axios.post(
  '/api/v1/analyze',
  { query },
  buildAuthHeader(token)
);

export const fetchActivity = token => axios.get(
  '/api/v1/activity',
  buildAuthHeader(token)
);
